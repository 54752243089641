
.app-cta3-wrapper {
    $primaryBtnColor: #3A185D;
    $secondaryBtnColor: #B29C75;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    
   
  
    &.tall {
      min-height:85vh;
      .backgroundimage-overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('https://www.medicalfitness.ch/Portals/0/2sxc/CTA3/Images/Overlay-CTA-multiply.png');
        background-repeat: no-repeat;
        background-size: 180% auto;
        mix-blend-mode: multiply;
        opacity:0.85;
        @include media-breakpoint-up(md) {
          background-size: 100% auto;
        }
  
        &:after {
          content:"";
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.22);
          background-image: url('https://www.medicalfitness.ch/Portals/0/2sxc/CTA3/Images/Overlay-CTA.png');
          background-repeat: no-repeat;
          background-size: 250% auto;
          @include media-breakpoint-up(md) {
            background-size: 120% auto;
          }
  
        }
      }
    }
    
    &.small {
      
      min-height:60vh;
      .app-cta3 { 
         @include media-breakpoint-up(lg) {
          padding-top: $grid-gutter-width*4;
          padding-bottom: $grid-gutter-width*4;
      }

      }
      .backgroundimage-overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('https://www.medicalfitness.ch/Portals/0/2sxc/CTA3/Images/Overlay-CTA-multiply.png');
        background-repeat: no-repeat;
        background-size: 100% auto;
        mix-blend-mode: multiply;
        opacity:0.85;
  
        &:after {
          content:"";
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-image: url('https://www.medicalfitness.ch/Portals/0/2sxc/CTA3/Images/Overlay-CTA.png');
          background-repeat: no-repeat;
          background-size: 100% auto;
  
        }
      }
      
      &.columns {
        h6 {
          font-size: 1em;
          border-bottom: 1px solid #000;
          padding-bottom:8px;
          margin-bottom:8px;
        }
      }
    }
    .app-cta3 {
      position: relative;
      padding-top: $grid-gutter-width*4;
      padding-bottom: $grid-gutter-width;

      &.app-cta3-text-image {
        padding-top: 0;
        padding-bottom: 0;
      }
  
      @include media-breakpoint-up(md) {
        padding-top: $grid-gutter-width*4;
        padding-bottom: $grid-gutter-width*2;
      }
      
      @include media-breakpoint-up(lg) {
          padding-top: $grid-gutter-width*5;
          padding-bottom: $grid-gutter-width*3;
      }
  
     
  
      // .app-cta3-text {
      //   p {
      //     font-size: 20px;
      //     line-height: 29px;
      //     margin-bottom: 0;
  
      //     @include media-breakpoint-up(md) {
      //       font-size: 30px;
      //       line-height: 42px;
      //     }
          
      //   }
      // }
  
      .app-cta3-text + .app-cta3-buttons {
        margin-top: 16px;
      }
  
      .app-cta3-buttons {
        .app-cta3-btn {
          font-size: 14px;
          font-weight: 700;
          color: $white;
          transition: all .4s ease-in-out ;
          margin-right:8px;
          margin-bottom:8px;
  
          &:first-child {
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.25), 0 0 10px rgba(0, 0, 0, 0.22);
          }
  
          @include media-breakpoint-up(md) {
            padding: 15px 20px;
            font-size: 16px;

          }
          
          &:first-child {
            background-color: $primaryBtnColor;
            border-color: $primaryBtnColor;
  
            &:hover {
              color: white;
              background-color: darken($primaryBtnColor, 15%);
              border-color: darken($primaryBtnColor, 15%);
            }
          }
  
          &:not(:first-child) {
            color:white;
            background-color: $secondaryBtnColor;
            border-color: $secondaryBtnColor;
           
  
            &:hover {
              color: $white;
              background-color: darken($secondaryBtnColor, 15%);
              border-color: darken($secondaryBtnColor, 15%);
            }
          }
        }
      }
  
      &.text-bright {
        color: $white;
  
        h1, h2, h3, h4, h5, h6 {
          color: $white;
          text-shadow: 0 0 30px rgba(0, 0, 0, 0.6);
          @include media-breakpoint-up(md) {
           text-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
          }
        }
      }
  
      &.text-darker {
        color: $primary;
        
        .app-cta3-buttons {
          .app-cta3-btn {
            &:first-child {
              color: $white;
  
              &:hover {
                color: $white;
              }
            }
  
            color: $black;
          }
        }
      }
    }
  }